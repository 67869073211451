<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            :default-href="`/resource/${resource.id}`"
          ></ion-back-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
          <img
            src="assets/icon/logo.png"
            style="height: 40px"
            alt="xtenso|xsample"
          />
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content
      :fullscreen="true"
      class="ion-padding"
      v-if="resource.id && activity"
    >
      <h2>{{ resource.name }} / {{ activity.name }}</h2>

      <Field
        name="name"
        validateOnInput
        v-slot="{ field, errorMessage, meta }"
        :rules="{ required: true, unique: names }"
        v-model="activityName"
      >
        <ion-item>
          <ion-label position="floating">Modifier nom</ion-label>
          <ion-input
            v-bind="field"
            name="name"
            placeholder="Machine X, Personne Y, Poste de travail W, Secteur S, ..."
            v-model="activityName"
            required
            @change="updateActivityName(meta.valid)"
          ></ion-input>
          <ion-text color="danger" slot="end" v-if="errorMessage">{{
            errorMessage
          }}</ion-text>
        </ion-item>
      </Field>

      <ion-item>
        <div class="xs-activity-select" :class="'type-' + activity.type">
          <ion-label position="floating">Type</ion-label>
          <ion-select
            class="xs-activity-select"
            :value="activity.type"
            interface="popover"
            :interface-options="{ cssClass: 'xs-activity-select' }"
            required
            @ion-change="updateActivityType($event.detail.value)"
          >
            <ion-select-option value="0" class="type-0"> </ion-select-option>
            <ion-select-option value="1" class="type-1" selected="selected">
            </ion-select-option>
            <ion-select-option value="2" class="type-2"> </ion-select-option>
          </ion-select>
        </div>
      </ion-item>

      <!-- add select -->

      <ion-card
        v-if="activity.data && activity.data.length === 0"
        color="warning"
        class="ion-text-center"
      >
        <ion-card-header>
          <ion-card-title
            >Aucune donnée à collecter actuellement.</ion-card-title
          >
        </ion-card-header>
        <ion-card-content>
          <p>Si besoin, ajoutez une ou plusieurs données à collecter.</p>
        </ion-card-content>
      </ion-card>

      <ion-list>
        <ion-list-header>Données associées à l'activité</ion-list-header>

        <ion-reorder-group
          :disabled="false"
          @ionItemReorder="doReorder($event)"
        >
          <ion-item
            v-for="activityData in activity.data"
            class="xs-activity-data"
            :class="'type-' + activityData.type"
            :key="activityData.name"
            lines="none"
          >
            <ion-label>
              <span v-if="activityData.type == 'image'" class="data-type"
                >img</span
              >
              <span v-if="activityData.type == 'text'" class="data-type"
                >abc</span
              >
              <span v-if="activityData.type == 'number'" class="data-type"
                >123</span
              >
              <span v-if="activityData.type == 'date'" class="data-type"
                >jan</span
              >
              <span v-if="activityData.type == 'list'" class="data-type"
                >x|y</span
              >
              <span v-if="activityData.type == 'checkbox'" class="data-type"
                >✓</span
              >
              {{ activityData.name }}
              <ion-text color="danger" v-if="activityData.required"
                >[requis]</ion-text
              >
            </ion-label>
            <div slot="end">
              <ion-button
                fill="clear"
                @click="
                  showAddEditActivityData(resource, activity, activityData.name)
                "
              >
                <ion-icon slot="start" :icon="createOutline"></ion-icon>
                Modifier
              </ion-button>

              <ion-button
                fill="clear"
                @click="deleteActivityData(activityData)"
              >
                <ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
              </ion-button>
            </div>
            <ion-reorder slot="end"></ion-reorder>
          </ion-item>
        </ion-reorder-group>
      </ion-list>

      <ion-button @click="showAddEditActivityData(resource, activity)"
        >Ajouter une donnée</ion-button
      >

      <ion-button color="danger" @click="deleteActivity()">
        Supprimer Activité
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { createOutline, trashOutline } from "ionicons/icons";

import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

import { computed, defineComponent, ref, watchEffect } from "vue";
import { Field } from "vee-validate";
import { useStore } from "@/store";
import { ActivityData } from "@/store/models";
import { useRoute } from "vue-router";
import {
  deleteActivityConfirm,
  deleteActivityDataConfirm,
  showAddEditActivityData,
} from "@/services/modals";

export default defineComponent({
  name: "Activity",
  components: {
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonButton,
    IonButtons,
    IonText,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonInput,
    IonReorder,
    IonReorderGroup,
    IonItem,
    IonCard,
    IonList,
    IonListHeader,
    IonIcon,
    IonBackButton,
    IonSelect,
    IonSelectOption,
    Field,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const activityName = ref();

    const resource = computed(() => {
      return (
        store.state.resources.find((r) => r.id === route.params.id) || {
          id: "",
          name: "",
          activities: [],
        }
      );
    });

    const activity = computed(() => {
      const activity =
        resource.value.activities[Number(route.params.activityIndex)];
      if (activity) {
        activity.data = activity.data || [];
      }
      return activity || {};
    });

    // update localVariable with store data;
    watchEffect(() => {
      activityName.value = activity.value.name;
    });

    function updateActivityName(valid: boolean) {
      if (valid) {
        store.dispatch("updateActivityPartial", {
          activity: activity.value,
          data: { name: activityName.value },
        });
      }
    }

    function updateActivityType(type: string) {
      store.dispatch("updateActivityPartial", {
        activity: activity.value,
        data: { type },
      });
    }

    const names = computed(() => {
      return resource.value.activities
        .map((a) => a.name)
        .filter((name: string) => name !== activity.value.name);
    });

    function doReorder(event: CustomEvent) {
      const updatedActivity = Object.assign({}, activity.value);
      updatedActivity.data = event.detail.complete(activity.value.data);
      store.dispatch("updateActivity", {
        resource: resource.value,
        activity: updatedActivity,
      });
    }

    return {
      trashOutline,
      createOutline,
      activity,
      names,
      resource,
      activityName,
      updateActivityName,
      updateActivityType,
      deleteActivity() {
        deleteActivityConfirm(resource.value, activity.value);
      },
      deleteActivityData(activityData: ActivityData) {
        deleteActivityDataConfirm(activity.value, activityData);
      },
      doReorder,
      showAddEditActivityData,
    };
  },
});
</script>
<style scoped>
.xs-activity-data {
  border: 1px solid #ccc;
  padding-left: 4px;
}
</style>
<style>
.data-type {
  font-weight: bold;
  padding: 0.2em;
  border: 1px solid;
  margin-right: 1em;
  width: 35px;
  display: inline-block;
  text-align: center;
}
</style>

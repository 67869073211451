
import { createOutline, trashOutline } from "ionicons/icons";

import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

import { computed, defineComponent, ref, watchEffect } from "vue";
import { Field } from "vee-validate";
import { useStore } from "@/store";
import { ActivityData } from "@/store/models";
import { useRoute } from "vue-router";
import {
  deleteActivityConfirm,
  deleteActivityDataConfirm,
  showAddEditActivityData,
} from "@/services/modals";

export default defineComponent({
  name: "Activity",
  components: {
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonButton,
    IonButtons,
    IonText,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonInput,
    IonReorder,
    IonReorderGroup,
    IonItem,
    IonCard,
    IonList,
    IonListHeader,
    IonIcon,
    IonBackButton,
    IonSelect,
    IonSelectOption,
    Field,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const activityName = ref();

    const resource = computed(() => {
      return (
        store.state.resources.find((r) => r.id === route.params.id) || {
          id: "",
          name: "",
          activities: [],
        }
      );
    });

    const activity = computed(() => {
      const activity =
        resource.value.activities[Number(route.params.activityIndex)];
      if (activity) {
        activity.data = activity.data || [];
      }
      return activity || {};
    });

    // update localVariable with store data;
    watchEffect(() => {
      activityName.value = activity.value.name;
    });

    function updateActivityName(valid: boolean) {
      if (valid) {
        store.dispatch("updateActivityPartial", {
          activity: activity.value,
          data: { name: activityName.value },
        });
      }
    }

    function updateActivityType(type: string) {
      store.dispatch("updateActivityPartial", {
        activity: activity.value,
        data: { type },
      });
    }

    const names = computed(() => {
      return resource.value.activities
        .map((a) => a.name)
        .filter((name: string) => name !== activity.value.name);
    });

    function doReorder(event: CustomEvent) {
      const updatedActivity = Object.assign({}, activity.value);
      updatedActivity.data = event.detail.complete(activity.value.data);
      store.dispatch("updateActivity", {
        resource: resource.value,
        activity: updatedActivity,
      });
    }

    return {
      trashOutline,
      createOutline,
      activity,
      names,
      resource,
      activityName,
      updateActivityName,
      updateActivityType,
      deleteActivity() {
        deleteActivityConfirm(resource.value, activity.value);
      },
      deleteActivityData(activityData: ActivityData) {
        deleteActivityDataConfirm(activity.value, activityData);
      },
      doReorder,
      showAddEditActivityData,
    };
  },
});
